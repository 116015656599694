import { useEffect, useRef, useState } from 'react'
import * as S from "./styles"
import { testimonials } from '../../utils/consts/testimonials'
import { clientsGrid } from '../../utils/consts/clientsGrid'
import TestimonialCard from '../../components/TestimonialCard'
import VideoComponent from '../../components/VideoComponent'

import video1 from "../../assets/videos/testimonialOne.mp4"
import video2 from "../../assets/videos/testimonialTwo.mp4"
import { renderClientImg } from '../../utils/functions/renderClientImg'

const Clients = () => {

  let counter = 0

  const cardsAreaRef = useRef<null | HTMLDivElement>(null)

  const [carouselItem, setCarouselItem] = useState(0)
  const [cardsGap, setCardsGap] = useState(48)
  const [carouselMargin, setCarouselMargin] = useState(0)

  const [carouselInterval, setCarouselInterval] = useState<NodeJS.Timer | null>(null)


  const handleChange = (id: number) => {
    setCarouselItem(id)

    if (carouselInterval) clearInterval(carouselInterval)
    counter = id > 0 ? id-- : testimonials.length - 1

    setCarouselInterval(setInterval(
      () => {
        if (counter === testimonials.length - 1) {
          counter = 0
        } else {
          counter++
        }

        setCarouselItem(counter)
      },
      7000
    ))
  }

  useEffect(() => {
    const calcGap = () => {
      const docWidth = document.body.clientWidth
      const cardWidth = 900
      const newGap = docWidth - (((carouselMargin * 2) + cardWidth) * 2)

      setCardsGap(newGap > 48 ? newGap : 48)
    }

    calcGap()
    setCarouselInterval(setInterval(
      () => {
        if (counter === testimonials.length - 1) {
          // eslint-disable-next-line
          counter = 0
        } else {
          counter++
        }

        setCarouselItem(counter)
      },
      7000
    ))

    const ml = (((900 / 100) * 76) + cardsGap)
    setCarouselMargin((ml / 2))

    window.onresize = calcGap
  }, [])

  return (
    <S.El>
      <S.TestimonialsArea>
        <S.SectionHeader>
          <S.SectionTitle>Quem usa nossa tecnologia</S.SectionTitle>
          <S.SectionDescription>Que amam nossas soluções!</S.SectionDescription>
        </S.SectionHeader>
        <S.Carousel>
          <S.CardsContainer>
            <S.CardsArea ref={cardsAreaRef} cardsGap={cardsGap} index={carouselItem}>
              {testimonials.map((t, k) => (
                <TestimonialCard
                  key={k}
                  active={k === carouselItem}
                  info={t}
                />
              ))}
            </S.CardsArea>
          </S.CardsContainer>
          <S.Controls>
            {testimonials.map((e, k) => (
              <S.Control
                key={k}
                active={k === carouselItem}
                onClick={() => handleChange(k)}
              />
            ))}
          </S.Controls>
        </S.Carousel>
      </S.TestimonialsArea>
      <S.ClientsGrid>
        {clientsGrid.map((c, k) => renderClientImg(c, k))}
      </S.ClientsGrid>
      <>
        <S.VideosAreaTitle>Depoimentos em vídeo</S.VideosAreaTitle>
        <S.VideosArea>
          <VideoComponent source={video1} id="videoSliderOne" />
          <VideoComponent source={video2} id="videoSliderTwo" />
        </S.VideosArea>
      </>
    </S.El>
  )

}


export default Clients
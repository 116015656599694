import { useState } from 'react'
import * as S from "./styles"


type Props = {
  disabled?: boolean;
  type: 'primary' | 'secondary';
  text: string;
  onClick?: () => void;
  loadingClick?: () => Promise<boolean>;
}

const Button = ({ disabled, type, text, onClick, loadingClick }: Props) => {

  const [isLoading, setIsLoading] = useState(false)

  const handleLoading = async () => {
    if (loadingClick) {
      setIsLoading(true)
      loadingClick()
        .then(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <S.El mode={type} onClick={loadingClick ? handleLoading : onClick} className={disabled ? 'disabled' : ''}>
      {isLoading ?
        <S.LoadingArea>
          <S.Dot />
          <S.Dot />
          <S.Dot />
        </S.LoadingArea>
        : text
      }
    </S.El>
  )
}

export default Button

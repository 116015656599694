import styled from "styled-components"
import { patterns } from "../../styled/patterns"


export const El = styled.section`
  display:grid;
  grid-template-columns:repeat(3, minmax(28.8rem, 1fr));
  gap:5.3rem;
  width:100%;
  justify-content:space-between;
  place-items:center;
  margin-bottom:200px; !important;

  @media (max-width: ${patterns._breakpoints.b1}px) {
    grid-template-columns:repeat(3, minmax(28.8rem, 1fr));
    margin-bottom:140px; !important;
  }

  @media (max-width: ${patterns._breakpoints.b2}px) {
    grid-template-columns:repeat(4, minmax(16.5rem, 1fr));
    margin-bottom:80px; !important;
  }

  @media (max-width: ${patterns._breakpoints.b3}px) {
    grid-template-columns:repeat(1, minmax(28.8rem, 1fr));
    margin-bottom:80px; !important;
  }
`
import styled from "styled-components"
import { patterns } from "../../styled/patterns"


export const Card = styled.div`
  width:fit-content;
  max-width:340px;
  padding:24px;
  display:flex;
  flex-direction:column;
  gap:2.4rem;
  border-radius:16px;
  box-shadow: 0px 40px 50px -16px rgba(99, 136, 177, 0.24);
  background-color:${p => p.theme.pallete.primary};
  transform:translateY(-6vh);
  transition:box-shadow .4s;
  cursor:pointer;

  svg {
    width:3.6rem;
    height:auto;
  }

  &:hover {
    box-shadow: 0px 40px 50px -16px rgba(99, 136, 177, 0.64);
  }
  

  @media (max-width: ${patterns._breakpoints.b2}px) AND (min-width: ${patterns._breakpoints.b3 + 1}px) {
    
    &:nth-child(1) {
      grid-column: 1/3;
    }
    &:nth-child(2) {
      grid-column: 3/5;
    }
    &:nth-child(3) {
      grid-column: 2/4;
    }
  }

  @media (max-width: ${patterns._breakpoints.b3}px) {
    grid-column: unset;
  }
`

export const Title = styled.span`
  font-size:1.8rem;
  font-weight:700;
  color:${p => p.theme.pallete.blue.natural};
  white-space:nowrap;
`

export const Description = styled.span`
  font-size:1.4rem;
  font-weight:400;
  color:${p => p.theme.pallete.grey};
`

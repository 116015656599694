import styled from "styled-components"


export const El = styled.button<{ mode: 'primary' | 'secondary' }>`
  border:none;
  outline:none;
  padding:1.6rem 0;
  border-radius:8px;
  font-size:1.8rem;
  font-weight:600;
  background-color:${p => p.mode === 'primary' ? p.theme.pallete.gold : 'transparent'};
  color:${p => p.mode === 'primary' ? p.theme.pallete.primary : p.theme.pallete.blue.natural};
  cursor:pointer;
  text-align:center;
  width:19.8rem;
  transition:opacity .4s;

  &.disabled {
    opacity:.6;
    cursor:auto;
  }
`

export const LoadingArea = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  gap:10px;
`

export const Dot = styled.div`
  width:8px;
  height:8px;
  border-radius:8px;
  background-color:#FFF;
  animation-name: bounce;
  animation-duration:.6s;
  animation-iteration-count: infinite;
  

  &:nth-child(1) {
    animation-delay: .0s;
  }

  &:nth-child(2) {
    animation-delay: .2s;
  }

  &:nth-child(3) {
    animation-delay: .4s;
  }

  @keyframes bounce {
    33% {
      transform:translateY(-50%);
    },
    66% {
      transform:translateY(50%);
    },
    100% {
      transform:translateY(-20%);
    }
  }
`

import React, { useRef, useState } from 'react'
import * as S from './styles'
import { ReactComponent as PlayIcon } from "../../assets/icons/play.svg"
import { ReactComponent as PauseIcon } from "../../assets/icons/pause.svg"

type Props = {
  source: string;
  id: string;
}

const VideoComponent = ({ source, id }: Props) => {

  const videoElRef = useRef<HTMLVideoElement | null>(null)
  const [videoStatus, setVideoStatus] = useState({
    playing: false,
    percentage: 0
  })

  const toggleVideoPlaying = () => {
    const isPlaying = videoStatus.playing
    const video = videoElRef.current

    if (video) {
      if (!isPlaying) {
        setVideoStatus({
          ...videoStatus,
          playing: true
        })
        video.play()
      } else {
        setVideoStatus({
          ...videoStatus,
          playing: false
        })
        video.pause()
      }
    }
  }

  const handleProgress = () => {
    const video = videoElRef.current

    if (video) {
      const currentTime = ((video.currentTime / video.duration) * 100)
      setVideoStatus({
        ...videoStatus,
        percentage: currentTime
      })
    }
  }

  const handleProgressDrag = (e: React.ChangeEvent<HTMLInputElement>) => {
    const video = videoElRef.current
    const draggedValue = Number(e.currentTarget.value)

    if (video) {
      setVideoStatus({
        ...videoStatus,
        percentage: draggedValue
      })
      video.currentTime = video.duration / 100 * draggedValue
    }
  }

  const parseCurrentToString = (seconds: number) => {
    const mins = Math.floor(seconds / 60)
    const restingSeconds = Math.floor(seconds % 60)

    return `${String(mins).padStart(2, "0")}:${String(restingSeconds).padStart(2, "0")}`
  }

  const parseTimeToString = (seconds: number) => {
    const minutesOf = Math.floor(seconds / 60)
    const restingSeconds = Math.floor((seconds % minutesOf) * 60)

    return `${String(minutesOf).padStart(2, "0")}:${String(restingSeconds).padStart(2, "0")}`
  }


  return (
    <S.Area>
      <S.PlayCover onClick={toggleVideoPlaying} isPlaying={videoStatus.playing}>
        <PlayIcon />
      </S.PlayCover>
      <video
        src={source}
        autoPlay={false}
        ref={videoElRef}
        onTimeUpdate={handleProgress}
      />
      <S.Controls isPlaying={videoStatus.playing}>
        <S.PlayPauseIconArea>
          {videoStatus.playing ?
            <PauseIcon
              onClick={toggleVideoPlaying}
            /> :
            <PlayIcon
              onClick={toggleVideoPlaying}
            />}
        </S.PlayPauseIconArea>
        <label htmlFor={id}>
          <S.ProgressSlider
            id={id}
            value={videoStatus.percentage}
            type='range'
            onChange={handleProgressDrag}
            min={0}
            max={100}
          />
        </label>
        <S.TimingText>
          {videoElRef.current && `${parseCurrentToString(videoElRef.current.currentTime)} / ${parseTimeToString(videoElRef.current.duration)}`}
        </S.TimingText>
      </S.Controls>
    </S.Area>
  )

}


export default VideoComponent

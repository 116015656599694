import { DefaultTheme } from "styled-components"

const theme: DefaultTheme = {
  pallete: {
    blue: {
      natural: '#224B78',
      dark: '#272C31',
    },
    gold: '#D26E51',
    primary: '#EFEFEF',
    grey: '#52565B',
  }
}


export default theme
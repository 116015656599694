import styled from "styled-components"



export const ClientCard = styled.div`
  padding:1.8rem;
  border-radius:16px;
  display:grid;
  place-items:center;
  width:fit-content;

  @media (max-width:1074px) {
    &.optional {
      display:none;
    }
  }
`
import { forwardRef, useState } from 'react'
import * as S from "./styles"
import { ServiceDetails } from '../../utils/types/serviceDetails'
import Button from '../../components/Button'


interface Props {
  details: ServiceDetails;
  last: boolean;
}

const Service = forwardRef<HTMLElement, Props>(({ details, last }, ref) => {

  const [showing, setShowing] = useState(false)

  const { reverse, image, isManaging, title, description, subdescription, pros } = details

  const toggleProsView = () => setShowing(!showing)


  return (
    <S.El ref={ref} className={last ? 'last' : ''}>
      <S.Content reverse={reverse} className='container'>
        <S.ImageArea isManagingItem={isManaging ?? false} reverse={reverse} className='ia'>
          <img src={image} alt={title} loading='lazy' />
        </S.ImageArea>
        <S.Info reverse={reverse}>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
          <S.SubDescription>
            <span>{subdescription.one}</span>
            <span>{subdescription.two}</span>
          </S.SubDescription>
          <Button
            type='primary'
            text='Saiba mais'
            onClick={toggleProsView}
          />
        </S.Info>
      </S.Content>
      <S.Pros showing={showing}>
        <S.ProsContainer>
          <S.ProsContent>
            {pros.map((p, k) => (
              <S.Pro key={k}>
                <S.ProTitle>{p.title}</S.ProTitle>
                <S.ProDescription>{p.description.replaceAll(";", "\n")}</S.ProDescription>
              </S.Pro>
            ))}
          </S.ProsContent>
        </S.ProsContainer>
      </S.Pros>
    </S.El>
  )

})


export default Service
import { ServiceCard } from "../types/serviceCard"

export const cardsInfo: ServiceCard[] = [
  {
    icon: 'sun',
    title: 'Energia Fotovoltaica',
    description: 'Gere sua própria energia limpa e reduza sua conta. Seu bolso e o planeta agradecem.'
  },
  {
    icon: 'industry',
    title: 'Gestão Energética',
    description: 'Soluções voltadas para obter o mais eficiente gerenciamento de energia elétrica para a indústria.'
  },
  {
    icon: 'lamp',
    title: 'Fornecimento de Energia',
    description: 'Cooperativa Solar que não requer investimentos e não é afetado por bandeiras tarifárias.'
  },
]
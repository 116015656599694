import { Testimonial } from "../types/testimonial"

export const testimonials: Testimonial[] = [
  {
    company: 'UATT',
    text: 'A gente não teve mais problemas com queima de placas, tanto de automação, máquinas e sistema de telefonia. Reduziu nossa manutenção e aumentou a produtividade. Estamos muito satisfeitos.',
    person: {
      name: 'Alécio José Costa',
      role: 'Diretor Industrial'
    }
  },
  {
    company: 'Gelotin',
    text: 'Realmente diminuiu a conta de energia em 10%, inclusive diminuiu a manutenção de equipamentos porque não ocorreu mais queima de motores…',
    person: {
      name: 'João Damian',
      role: 'Proprietário'
    }
  },
  {
    company: 'CAJADAN',
    text: 'Nós tínhamos um problema de queima de placas das nossas máquinas, [..] despesa com placas em torno de R$ 4.000,00/mês. Após a instalação dos filtros capacitivos reduzimos de 20 para no máximo 4 placas/mês. Foi um ganho bastante significativo.',
    person: {
      name: 'Andrey',
      role: 'Diretor Industrial'
    }
  },
  {
    company: 'REPECON',
    text: 'Percebemos uma grande redução principalmente em manutenção de equipamentos e máquinas, dando um grande ganho para a nossa empresa.',
    person: {
      name: 'Fernando Nienkotter',
      role: 'Diretor Comercial'
    }
  },
]
import React, { forwardRef } from 'react'
import * as S from "./styles"
import ServiceCard from '../../components/ServiceCard'
import { cardsInfo } from '../../utils/consts/cardsInfo'

type Props = {
  servicesRelations: React.MutableRefObject<HTMLElement | null>[];
}

const ServicesResume = forwardRef<HTMLElement, Props>(({ servicesRelations }, ref) => {


  const handleClick = (refId: number) => {
    servicesRelations[refId].current?.scrollIntoView({ behavior: 'smooth' })
  }


  return (
    <S.El ref={ref} className='container'>
      {cardsInfo.map((s, k) => (
        <ServiceCard
          key={k}
          icon={s.icon}
          title={s.title}
          description={s.description}
          onClick={() => handleClick(k)}
        />
      ))}
    </S.El>
  )

})


export default ServicesResume
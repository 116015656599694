import React, { forwardRef, useEffect, useState } from 'react';
import * as handles from '../../utils/consts/handles';
import * as S from './styles';

import { useApi } from '../../Api';
import { ReactComponent as CheckedIcon } from '../../assets/icons/checked.svg';
import { ReactComponent as LeafIcon } from '../../assets/icons/leaf.svg';
import Button from '../../components/Button';
import FormInput from '../../components/Input';
import { formErrors } from '../../utils/consts/formErrors';
import { ErrorsChecks } from '../../utils/types/errorschecks';

type Props = {
  toggleModal: (show: boolean, title: string, message: string) => void;
  ref: React.ForwardRefExoticComponent<HTMLElement>;
};

const Contact = forwardRef<HTMLElement, Props>(({ toggleModal }, ref) => {
  const Api = useApi();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [message, setMessage] = useState('');
  const [isRobot, setIsRobot] = useState(true);
  const [isSendDisabled, setSendDisabled] = useState(true);
  const [errors, setErrors] = useState<ErrorsChecks>({
    name: { is: false, message: '' },
    email: { is: false, message: '', match: false },
    telephone: { is: false, message: '', match: false },
    message: { is: false, message: '' },
  });

  const cleanFields = () => {
    setName('');
    setEmail('');
    setTelephone('');
    setMessage('');

    setIsRobot(true);
    setSendDisabled(true);

    setErrors({
      name: { is: false, message: '' },
      email: { is: false, message: '', match: false },
      telephone: { is: false, message: '', match: false },
      message: { is: false, message: '' },
    });
  };

  const handleSend = async () => {
    if (!isSendDisabled) {
      if (errors.email.match && errors.telephone.match) {
        const send = await Api.sendEmail(name, email, telephone, message);
        if (send.status === 400 || send.status === 404) {
          toggleModal(
            true,
            'Ops.. Houve um erro',
            'Tente novamente mais tarde'
          );
        } else {
          toggleModal(
            true,
            'Email enviado com sucesso',
            'Entraremos em contato em breve'
          );

          cleanFields();
        }
      } else {
        let newErrorsObj: ErrorsChecks = { ...errors };

        if (!errors.email.match) {
          newErrorsObj.email = {
            ...newErrorsObj.email,
            is: true,
            message: formErrors.email.invalid,
          };
        }
        if (!errors.telephone.match) {
          newErrorsObj.telephone = {
            ...newErrorsObj.telephone,
            is: true,
            message: formErrors.telephone.invalid,
          };
        }

        setErrors(newErrorsObj);
      }
    }

    return true;
  };

  useEffect(() => {
    const fields = { name, email, telephone, message };

    const fieldsFilled = Object.entries(fields).every(
      (f) => f[1].trim() !== ''
    );
    const noErrors = Object.entries(errors).every(
      (field) => field[1].is === false
    );

    if (fieldsFilled && noErrors && isRobot === false) {
      setSendDisabled(false);
    } else {
      setSendDisabled(true);
    }
  }, [errors, isRobot, name, email, telephone, message]);

  return (
    <S.El ref={ref}>
      <S.Container className="container">
        <S.ContactInfo>
          <S.ContactTitle>Entre em contato!</S.ContactTitle>
          <S.ContactText>
            Fale com nossa equipe utilizando o formulário para dúvidas e
            sugestões, ou ligando (também pelo WhatsApp) para (48) 99674-4000.
          </S.ContactText>
          <S.ContactText>
            A Delta é uma empresa especializada em gestão de energia,
            estabelecida em Florianópolis – SC.
          </S.ContactText>
          <S.EcoArea>
            <LeafIcon />
            <S.EcoText>
              <span>
                Nossa empresa investe em
                <br />
              </span>
              <span>Ecoflorestamento</span>
            </S.EcoText>
          </S.EcoArea>
        </S.ContactInfo>
        <S.FormArea onSubmit={(e) => e.preventDefault()}>
          <FormInput
            placeholder="Nome *"
            value={name}
            onChange={(t) =>
              handles.handleName({
                t,
                errors,
                setErrors,
                setState: setName,
              })
            }
            error={errors.name}
          />
          <FormInput
            placeholder="Email *"
            value={email}
            onChange={(t) =>
              handles.handleEmail({
                t,
                errors,
                setErrors,
                setState: setEmail,
              })
            }
            error={errors.email}
          />
          <FormInput
            placeholder="Telefone *"
            value={telephone}
            onChange={(t) =>
              handles.handleTelephone({
                t,
                errors,
                setErrors,
                setState: setTelephone,
              })
            }
            error={errors.telephone}
          />
          <S.MessageArea
            placeholder={
              errors.message.is
                ? errors.message.message
                : 'Escreva aqui sua mensagem *'
            }
            value={message}
            onChange={(e) =>
              handles.handleMessage({
                t: e.target.value,
                errors,
                setErrors,
                setState: setMessage,
              })
            }
            isError={errors.message.is}
          />
          <S.FormBottom>
            <S.CheckboxArea onClick={() => setIsRobot(!isRobot)}>
              <S.CheckSquare>{!isRobot ? <CheckedIcon /> : null}</S.CheckSquare>
              <span>Não sou um robô</span>
            </S.CheckboxArea>
            <Button
              disabled={isSendDisabled}
              type="primary"
              text="Enviar"
              loadingClick={handleSend}
            />
          </S.FormBottom>
        </S.FormArea>
      </S.Container>
    </S.El>
  );
});

export default Contact;

import styled from "styled-components"

import BgNormal from "../../assets/images/bgservice-right.webp"
import BgReverse from "../../assets/images/bgservice-reverse.webp"
import { patterns } from "../../styled/patterns"

export const El = styled.section`
  display:flex;
  flex-direction:column;
  margin-bottom:200px; !important;

  @media (max-width: ${patterns._breakpoints.b1}px) {
    grid-template-columns:repeat(2, minmax(28.8rem, 1fr));
    margin-bottom:140px; !important;
  }

  @media (max-width: ${patterns._breakpoints.b3}px) {
    grid-template-columns:repeat(1, minmax(28.8rem, 1fr));
    margin-bottom:80px; !important;
  }
  
  @media (max-width:480px) {
    
    & > div > div.ia img { width:45%; }
  }
`

export const Content = styled.div<{ reverse: boolean; }>`
  display:flex;
  flex-direction:${p => p.reverse ? 'row-reverse' : 'row'};
  gap:7.6rem;
  background:url(${p => p.reverse ? BgReverse : BgNormal});
  background-position:${p => p.reverse ? 'right' : 'left'};
  background-size:contain;
  background-repeat:no-repeat;

  @media (max-width:${patterns._breakpoints.b2}px) {
    flex-direction:column-reverse;
    background:none;
  }
`

export const ImageArea = styled.div<{ isManagingItem?: boolean; reverse: boolean; }>`
  max-height:47.2rem;
  position:relative;
  flex:1;
  
  &::before {
    content:'';
    position:absolute;
    height:100%;
    ${p => p.reverse ? 'left' : 'right'}:100%;
    background-color:${p => p.theme.pallete.blue.natural};
    width:100vw;
    z-index:-1;
  }

  ${p => !p.isManagingItem ? `
    display:grid;
    place-items:center;
  `: ``}

  img {
    height:80%;
    max-height:42rem;
    width:auto;

    ${p => p.isManagingItem ? `
      @media (min-width:${patterns._breakpoints.b2}px) {
        transform:translateY(10%);
      }
    `: ``}
  }

  @media (max-width:${patterns._breakpoints.b2}px) {
    background:url(${p => p.reverse ? BgReverse : BgNormal});
    background-position:${p => p.reverse ? 'right' : 'left'};
    background-size:60%;
    background-repeat:no-repeat;
  }

  @media (max-width:940px) {
    background-size:contain;
  }

  @media (max-width:460px) {
    background-size:70% 100%;
    padding:24px 0;

    &::before {
      top:0;
    }

    img {
      width:70%;
      height:auto;
      max-height:unset;
    }
  }
`

export const Info = styled.div<{ reverse: boolean; }>`
  display:flex;
  flex-direction:column;
  flex:1;
  gap:3.2rem;
  padding-${p => p.reverse ? 'left' : 'right'}:64px;
  padding-bottom:50px;
  align-items:flex-start;

  @media (max-width:${patterns._breakpoints.b2}px) {
    align-items:center;
    padding:${patterns.containerPadding};
  }

  @media (max-width:520px) {
    padding:0;
  }
`

export const Title = styled.h2`
  font-size:4.8rem;
  font-weight:700;
  color:${p => p.theme.pallete.blue.dark};
  width:100%;

  @media (max-width:${patterns._breakpoints.b2}px) {
    width:unset;
  }
`

export const Description = styled.span`
  font-size:2.4rem;
  font-weight:500;
  color:${p => p.theme.pallete.grey};
  white-space:wrap;
  max-width:63rem;
`

export const SubDescription = styled.div`
  display:flex;
  gap:48px;

  span {
    font-size:1.4rem;
    font-weight:400;
    color:${p => p.theme.pallete.grey};
    max-width:29.2rem;
  }
`

export const Pros = styled.div<{ showing: boolean; }>`
  display:grid;
  grid-template-rows:${p => p.showing ? 1 : 0}fr;
  transition:grid-template-rows .4s;
  overflow:hidden;
`

export const ProsContainer = styled.div`
  overflow:hidden;
  background-color:${p => p.theme.pallete.blue.natural};
`

export const ProsContent = styled.div`
  padding:80px 0;
  display:grid;
  gap:4.8rem;
  color:${p => p.theme.pallete.primary};
  grid-template-columns:repeat(4, 28rem);
  justify-content:space-between;
  max-width:1440px;
  margin:0 auto;
  padding:${patterns.biggerPadding};

  @media (max-width:${patterns._breakpoints.b2}px) {
    grid-template-columns:repeat(2, minmax(288px, 1fr));
  }

  @media (max-width:${patterns._breakpoints.b3}px) {
    grid-template-columns:repeat(1, minmax(288px, 360px));
  }
`

export const Pro = styled.div`
  display:flex;
  flex-direction:column;
  gap:1.6rem;
  width:100%;
  max-width:28rem;

  @media (max-width:${patterns._breakpoints.b2}px) {
    max-width:56rem;
  }
`

export const ProTitle = styled.span`
  font-size:1.8rem;
  font-weight:700;
`

export const ProDescription = styled.span`
  white-space:wrap;
  font-size:1.4rem;
  font-weight:400;
`

import styled from "styled-components"


export const Box = styled.div<{ showing: boolean; }>`
  position:fixed;
  top:${p => p.showing ? '4rem' : '-50%'};
  left:50%;
  background-color:#224B78;
  box-shadow: 0px 12px 20px -6px rgba(0,0,0, 0.4);
  border-radius:8px;
  padding:3.2rem;
  display:flex;
  flex-direction:column;
  transform:translateX(-50%);
  opacity:${p => p.showing ? 1 : 0};
  gap:1rem;
  z-index:1000;

  transition:top 1s, opacity .4s;
`

export const Title = styled.h3`
  color:white;
  font-size:3rem;
`

export const Feedback = styled.span`
  color:white;
  font-size:1.8rem;
  font-weight:300;
`

export const Button = styled.button`
  border:none;
  outline:none;
  padding:1.6rem 5.4rem;
  width:fit-content;
  border-radius:8px;
  font-size:1.4rem;
  font-weight:600;
  background-color:${p => p.theme.pallete.gold};
  color:${p => p.theme.pallete.primary};
  cursor:pointer;
  text-align:center;
  transition:opacity .4s;
  place-self:flex-end;
  margin-top:4rem;
`
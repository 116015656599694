import altona from '../../assets/companies/altona.webp'
import alusupra from '../../assets/companies/alusupra.webp'
import cajadan from '../../assets/companies/cajadan.webp'
import cral from '../../assets/companies/cral.webp'
import digem from '../../assets/companies/digem.webp'
import dmi from '../../assets/companies/dmi-image.webp'
import dori from '../../assets/companies/dori.webp'
import esmeralda from '../../assets/companies/esmeralda.webp'
import marigelo from '../../assets/companies/marigelo.webp'
import gelotin from '../../assets/companies/gelotin.webp'
import golden from '../../assets/companies/golden.webp'
import jahn from '../../assets/companies/jahn.webp'
import librelato from '../../assets/companies/librelato.webp'
import megax from '../../assets/companies/megax.webp'
import mezzani from '../../assets/companies/mezzani.webp'
import nestle from '../../assets/companies/nestle.webp'
import oct from '../../assets/companies/oct_image.webp'
import personalGlass from '../../assets/companies/personal-glass.webp'
import repecon from '../../assets/companies/repecon.webp'
import sva from '../../assets/companies/sva.webp'
import turbosul from '../../assets/companies/turbosul.webp'
import uatt from '../../assets/companies/uatt.webp'
import ultraRad from '../../assets/companies/ultra-rad.webp'
import unividros from '../../assets/companies/unividros.webp'
import glasstem from '../../assets/companies/glasstem.webp'

export const clientsGrid = [
  {
    companyName: 'Altona',
    logo: altona,
  },
  {
    companyName: 'Alusupra',
    logo: alusupra,
  },
  {
    companyName: 'Cajadan',
    logo: cajadan,
  },
  {
    companyName: 'Cral',
    logo: cral,
  },
  {
    companyName: 'Digem',
    logo: digem,
  },
  {
    companyName: 'DMI',
    logo: dmi,
  },
  {
    companyName: 'Dori',
    logo: dori,
  },
  {
    companyName: 'Esmeralda',
    logo: esmeralda,
  },
  {
    companyName: 'Marigelo',
    logo: marigelo,
  },
  {
    companyName: 'Gelotin',
    logo: gelotin,
  },
  {
    companyName: 'Golden',
    logo: golden,
  },
  {
    companyName: 'Jahn',
    logo: jahn,
  },
  {
    companyName: 'Librelato',
    logo: librelato,
  },
  {
    companyName: 'Megax',
    logo: megax,
  },
  {
    companyName: 'Mezzani',
    logo: mezzani,
  },
  {
    companyName: 'Nestle',
    logo: nestle,
  },
  {
    companyName: 'Oct',
    logo: oct,
  },
  {
    companyName: 'Personal Glass',
    logo: personalGlass,
  },
  {
    companyName: 'Repecon',
    logo: repecon,
  },
  {
    companyName: 'Sva',
    logo: sva,
  },
  {
    companyName: 'Turbosul',
    logo: turbosul,
  },
  {
    companyName: 'Uatt',
    logo: uatt,
  },
  {
    companyName: 'Ultra Rad',
    logo: ultraRad,
  },
  {
    companyName: 'Unividros',
    logo: unividros,
  },
  {
    companyName: 'Glasstem',
    logo: glasstem,
  },
]
import styled from "styled-components"


export const Card = styled.div<{ active: boolean; }>`
  width:90rem;
  padding:3.2rem 3.2rem 3.2rem 6.4rem;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap:2.7rem;
  border-radius:32px;
  box-shadow: 0px 40px 50px -16px rgba(99, 136, 177, 0.24);
  background-color:${p => p.theme.pallete.gold};
  box-shadow:0 60px 40px -40px rgba(210,110,81,.3);
  transition: opacity .4s;

  opacity:${p => !p.active ? `.4` : '1'};
  filter:blur(${p => !p.active ? `2` : '0'}px);
  

  svg {
    width:3.6rem;
    height:auto;
  }
  
  @media (max-width:640px) {
    max-width:80vw;
  }
`

export const Testimonial = styled.div`
  color:${p => p.theme.pallete.primary};
  display:flex;
  flex-direction:column;
  gap:1.6rem;
`

export const CompanyName = styled.span`
  font-size:4.8rem;
  font-weight:700;
`

export const WhatSaid = styled.span`
  font-size:2.4rem;
  font-weight:400;
  line-height:140%;
  
  @media (max-width:540px) {
    font-size:2.2rem;
  }
`

export const Person = styled.div`
  display:flex;
  align-items:center;
  gap:1.6rem;
  color:${p => p.theme.pallete.primary};

  svg {
    width:2.4rem;
    height:auto
  }
`

export const PersonInfo = styled.span`
  font-size:1.4rem;
  font-weight:400;
  white-space:nowrap;
  color:currentColor;
`

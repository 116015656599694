import styled from "styled-components"


export const El = styled.footer`
  padding:2rem 16.6rem;
  background-color:${p=>p.theme.pallete.blue.dark};
  font-size:1.4rem;
  font-weight:400;

  @media (max-width:980px) {
    flex-direction:column;
    align-items:center;
    gap:1rem;
  }

  @media (max-width:640px) {
    padding:2rem 6.4rem;
  }
`

export const Container = styled.div`
  display:flex;
  justify-content:space-between
  `

export const Info = styled.div`
  text-align:center;

  a {
    font-family:Montserrat;
    text-decoration:none;
    color:${p => p.theme.pallete.primary};

    &:hover {
      text-decoration:underline;
    }
  }
`

export const Rights = styled.span`
  color:${p => p.theme.pallete.primary};
`

export const MadeBy = styled.span`
  color:${p => p.theme.pallete.primary};
  text-align:center;

  a {
    font-family:Montserrat;
    text-decoration:none;
    color:${p => p.theme.pallete.primary};

    &:hover {
      text-decoration:underline;
    }
  }
`

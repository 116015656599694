const masks = {
  email: (value: string) => {
    const emailPattern = /^[\w-.!#$&*+%¨]+@([\w-.!#$&*+¨]+\.)+[\w]+/gi

    return ({
      parsed: value,
      matched: emailPattern.test(value)
    })
  },
  phone: (value: string) => {
    const phonePattern = /^(\([0-9]{2}\)) [0-9]{4,5}-[0-9]{4}$/
    const parsed = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(\d{4})\d+?$/, '$1')

    return ({
      parsed,
      matched: phonePattern.test(parsed)
    })
  },
}


export default masks
import * as S from './styles'


type Props = {
  placeholder: string;
  value: string;
  onChange: (t: string) => void;
  error: {
    is: boolean;
    message: string;
  }
}

const FormInput = ({ placeholder, value, onChange, error }: Props) => {


  return (
    <S.Input
      placeholder={error.is ? error.message : placeholder}
      value={value}
      onChange={e => onChange(e.target.value)}
      isError={error.is}
    />
  )

}


export default FormInput

export const formErrors = {
  name: {
    empty: 'Campo "nome" é obrigatório',
    invalid: "Digite um nome"
  },
  email: {
    empty: 'Campo "email" é obrigatório',
    invalid: "Digite um email válido"
  },
  telephone: {
    empty: 'Campo "telefone" é obrigatório',
    invalid: "Digite um telefone válido"
  },
  message: {
    empty: 'Campo "mensagem" é obrigatório',
    invalid: "Digite uma mensagem"
  }
}
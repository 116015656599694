import styled from "styled-components"


export const Input = styled.input<{ isError: boolean; }>`
  border:1px solid ${p => p.isError ? p.theme.pallete.gold : "transparent"};
  outline:none;
  border-radius:8px;
  font-size:1.8rem;
  font-weight:600;
  background:${p => p.isError ? `
    linear-gradient(0deg, rgba(210, 110, 81, 0.3), rgba(210, 110, 81, 0.3)), #FFFFFF;
  ` : p.theme.pallete.primary};
  color:${p => p.isError ? p.theme.pallete.gold : p.theme.pallete.blue.dark};
  padding:2.1rem 3.2rem;
  transition:border-color .3s, color .3s, background-color .3s;

  &::placeholder {
    color:${p => p.isError ? p.theme.pallete.gold : p.theme.pallete.grey};
    transition:color .3s;
  }
`
import styled from "styled-components"

import bg from '../../assets/images/hero-bg.webp'

export const El = styled.section`
  min-height:97vh;
  background:url(${bg});
  background-position:center;
  background-size:cover;
  display:flex;
  flex-direction:column;
  justify-content:center;
  gap:11rem;
  padding:6.4rem;

  svg {
    height:7rem;
  }

  @media (max-width:500px) {
    background-position-x:70%;
  }
`

export const Content = styled.div`
  display:flex;
  flex-direction:column;
  gap:6.4rem;
`

export const Texts = styled.div`
  color:${p => p.theme.pallete.primary};
  display:flex;
  flex-direction:column;
  gap:1.6rem;
  width:min-content;
`

export const Title = styled.h1`
  font-size:700;
  font-size:4.8rem;
  white-space:nowrap;
`

export const Phrase = styled.h2`
  font-size:2.4rem;
  font-weight:400;
  white-space:wrap;
`

export const ButtonsArea = styled.div`
  display:flex;
  gap:3.2rem;
`

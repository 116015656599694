type Props = {
  show?: boolean;
  title?: string;
  message?: string;
  setModalInfo: React.Dispatch<React.SetStateAction<{
    showing: boolean;
    title: string;
    message: string;
  }>>
  modalShowing: boolean;
}

export const toggleModal = ({ show, title, message, setModalInfo, modalShowing }: Props) => {
  if (show && title && message) {
    setModalInfo({
      showing: show ?? !modalShowing,
      title,
      message
    })
  } else {
    setModalInfo({ showing: false, title: '', message: '' })
  }
}
import styled from "styled-components"


export const Area = styled.div`
  position:relative;
  flex:1;
  max-width:52.4rem;
  border-radius:16px;
  overflow:hidden;
  min-width:240px;

  video {
    width:100%;
    height:auto;
  }

  @media (max-width:800px) {
    max-width:80rem;
  }
`

export const PlayCover = styled.div<{ isPlaying: boolean; }>`
  position:absolute;
  display:grid;
  place-items:center;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background-color:rgba(0,0,0,.4);
  cursor:pointer;
  z-index:5;
  opacity:${p => p.isPlaying ? 0 : 1};
  transition:opacity .3s;

  svg {
    width:7.6rem;
    height:auto;
    cursor:pointer;
  }

  &:hover {
    & ~ div {
      opacity:1;
    }
  }
`

export const Controls = styled.div<{ isPlaying: boolean; }>`
  z-index:7;
  opacity:${p => p.isPlaying ? 1 : 0};
  transition:opacity .3s;
  position:absolute;
  height:fit-content;
  left:0;
  bottom:0;
  right:0;
  padding:8px 12px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  gap:2rem;
  background-color:rgba(0,0,0,.8);
  opacity:0;

  svg {
    width:2.4rem;
    height:auto;
    cursor:pointer;
  }

  label { flex:1; }

  &:hover {
    opacity:1;
  }
`

export const PlayPauseIconArea = styled.div``

export const ProgressSlider = styled.input`
  height:3px;
  color:${p => p.theme.pallete.blue.natural};
  width:100%;

  &::-webkit-slider-thumb {
    background:${p => p.theme.pallete.blue.natural};
    height:12px;
    cursor:pointer;
  }
`

export const TimingText = styled.div`
  color:white;
  font-size:1.2rem;
  width:fit-content;
  white-space:nowrap;
`

import * as S from "./styles"

const Footer = () => {


  return (
    <S.El>
      <S.Container className='container'>
        <S.Info>
          <S.Rights>2023 © Delta Energy - Todos os diretos reservados. </S.Rights>
        </S.Info>
        <S.MadeBy>Desenvolvido por <a
          href='https://statustech.app/'
          target="_blank"
          rel="noreferrer"
        >StatusTech</a>
        </S.MadeBy>
      </S.Container>
    </S.El>
  )

}


export default Footer
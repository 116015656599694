import * as S from './styles'


type Props = {
  showing: boolean;
  title: string;
  feedback: string;
  toggleModal: () => void;
}

const FeedbackModal = ({ showing, title, feedback, toggleModal }: Props) => {



  return (
    <S.Box showing={showing}>
      <S.Title>{title}</S.Title>
      <S.Feedback>{feedback}</S.Feedback>
      <S.Button onClick={toggleModal}>
        Fechar
      </S.Button>
    </S.Box>
  )

}


export default FeedbackModal
import { createGlobalStyle } from "styled-components"

const Global = createGlobalStyle`
  body {
    background-color:${p => p.theme.pallete.primary};
  }

  .container {
    width:100%;
    max-width:1438px;
    margin:0 auto;
    padding:0 64px;
  }

  @media (max-width:520px) {
    
    .container {
      padding:0 24px;

    }
  }
`


export default Global
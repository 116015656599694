import React, { forwardRef } from 'react'
import * as S from "./styles"

import { ReactComponent as Logo } from "../../assets/icons/LogoFull.svg"
import Button from '../../components/Button'



type Props = {
  servicesRf: React.MutableRefObject<HTMLElement | null>;
  contactRf: React.MutableRefObject<HTMLElement | null>;
}

const Hero = forwardRef<HTMLElement, Props>(({ servicesRf, contactRf }, ref) => {


  return (
    <S.El>
      <S.Content className='container'>
        <Logo />
        <S.Texts>
          <S.Title>Gestão de energia</S.Title>
          <S.Phrase>Economia, sustentabilidade, gestão e eficiência energética.</S.Phrase>
        </S.Texts>
        <S.ButtonsArea>
          <Button
            type='primary'
            text='Nossas soluções'
            onClick={() =>
              servicesRf.current?.scrollIntoView({ behavior: 'smooth' })
            }
          />
          <Button
            type='primary'
            text='Entre em contato'
            onClick={() =>
              contactRf.current?.scrollIntoView({ behavior: 'smooth' })
            }
          />
        </S.ButtonsArea>
      </S.Content>
    </S.El>
  )

})


export default Hero
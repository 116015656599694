import a from "axios"


export const useApi = () => {

  const baseUrl = process.env.REACT_APP_BASE_URL

  const service = a.create({
    baseURL: baseUrl,
    headers: {
      Accept: "application/json",
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })


  return ({
    sendEmail: async (
      name: string,
      emailToReply: string,
      telephone: string,
      message: string,
    ) => {
      const send = await service.post('/sendemail', {
        personName: name,
        emailToReply,
        telephone,
        message,
      })

      return ({ status: send.status })
    },
  })
}
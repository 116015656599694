import styled from "styled-components"
import { patterns } from "../../styled/patterns"


export const El = styled.section``

export const TestimonialsArea = styled.div`
  display:flex;
  flex-direction:column;
  gap:4.8rem;
`

export const SectionHeader = styled.div`
  text-align:center;
`

export const SectionTitle = styled.h2`
  font-size:4.8rem;
  font-weight:700;
  color:${p => p.theme.pallete.blue.dark};
  margin-bottom:3rem;
`

export const SectionDescription = styled.span`
  font-size:2.4rem;
  font-weight:500;
  color:${p => p.theme.pallete.grey};
  white-space:wrap;
  max-width:63rem;
`

export const Carousel = styled.div`
  display:flex;
  flex-direction:column;
  gap:4.8rem;
  width:100vw;
  overflow:hidden;
  position:relative;
`

export const CardsArea = styled.div<{ cardsGap: number; index: number; }>`
  position:absolute;
  display:flex;
  width:fit-content;
  overflow:visible;
  gap:${p => p.cardsGap}px;
  left: calc(50% - 45rem);
  margin-left:calc(-${p => p.index * 90}rem - ${p => p.cardsGap * p.index}px);
  transition:margin-left .4s;
  
  @media (max-width:540px) {
    left: calc(50% - 40vw);
    gap:5vw;
    margin-left:calc(-${p => p.index * 80}vw - ${p => 5 * p.index}vw);
  }
`

export const CardsContainer = styled.div`
  display:flex;
  width:fit-content;
  overflow:visible;
  width:fit-content;
  min-height:300px;
`

export const Controls = styled.div`
  display:flex;
  justify-content:center;
  gap:1.6rem;
`

export const Control = styled.div<{ active: boolean; }>`
  width:1.6rem;
  height:1.6rem;
  border-radius:1.6rem;
  background-color:${p => p.active ? p.theme.pallete.gold : p.theme.pallete.blue.dark};
  cursor:pointer;
`

export const ClientsGrid = styled.div`
  display:grid;
  grid-template-columns: repeat(5, 19.6rem);
  justify-content:space-between;
  gap:3.2rem;
  padding:0 16.6rem;
  margin:64px 0;
  justify-content:center;

  @media (max-width:${patterns._breakpoints.b2}px) {
    gap:0.2rem;
    grid-template-columns: repeat(auto-fill, 19.6rem);
  }

  @media (max-width:800px) {
    width:100%;
    padding:0 6.4rem;
  }
`

export const VideosAreaTitle = styled.h3`
  font-size:2.4rem;
  font-weight:500;
  color:${p => p.theme.pallete.grey};
  text-align:center;
`

export const VideosArea = styled.div`
  display:flex;
  gap:6rem;
  justify-content:center;
  transform:translateY(32px);
  padding:0 16.6rem;
  margin-top:32px;

  @media (max-width:800px) {
    flex-direction:column;
    align-items:center;
    padding:0 8.8rem;
  }
`
import * as S from './styles'

import { ReactComponent as CommentIcon } from "../../assets/icons/comment.svg"
import { Testimonial } from '../../utils/types/testimonial'


type Props = {
  active: boolean;
  info: Testimonial;
}

const TestimonialCard = ({ active, info }: Props) => {

  const { company, text, person } = info

  return (
    <S.Card active={active}>
      <S.Testimonial>
        <S.CompanyName>{company}</S.CompanyName>
        <S.WhatSaid>{text}</S.WhatSaid>
      </S.Testimonial>
      <S.Person>
        <CommentIcon />
        <S.PersonInfo>{`${person.name} – ${person.role}`}</S.PersonInfo>
      </S.Person>
    </S.Card>
  )

}


export default TestimonialCard
import { ServiceDetails } from "../types/serviceDetails"

import PanelsImg from "../../assets/images/panels.webp"
import EnergyManagementImg from "../../assets/images/energy-management.webp"
import GreenLampImg from "../../assets/images/green-lamp.webp"


export const servicesDetails: ServiceDetails[] = [
  {
    reverse: true,
    image: PanelsImg,
    title: 'Energia Fotovoltaica',
    description: 'Energia elétrica limpa e renovável a partir de luz solar, inclusive em dias nublados ou chuvosos!',
    subdescription: {
      one: 'Gere sua própria energia limpa e reduza sua conta de luz. Seu bolso e o planeta agradecem. A revolução já começou!\nPráticas econômicas e inteligentes no uso da energia.',
      two: 'Fazemos o levantamento de dados, consultoria, implementação e gestão energética no seu projeto de energia fotovoltaica.'
    },
    pros: [
      {
        title: 'Economia',
        description: 'A economia é só o começo dos benefícios da energia solar. Esta energia limpa traz um conjunto de outros benefícios, de sustentáveis à econômicos, que podem beneficiar tanto o consumidor quanto o planeta.'
      },
      {
        title: 'Benefícios',
        description: "Ajuda a reduzir os rastros de carbono do nosso país. Além de segurança e confiabilidade, ela contribui para o suprimento de energia à população. Tecnologia de ponta que ajuda na criação de empregos."
      },
      {
        title: 'Tecnologia',
        description: 'A luz solar é absorvida nas células solares, também chamadas fotovoltaicas ou fotoelétricas que são feitas de material semicondutor, geralmente de cristais de silício, que se encontram em Placas Fotovoltaicas.'
      },
      {
        title: 'Retorno financeiro',
        description: 'Tecnologia com alto retorno financeiro que agrega valor ao seu imóvel e reduz drasticamente sua conta de luz.'
      }
    ]
  },
  {
    reverse: false,
    image: EnergyManagementImg,
    isManaging:true,
    title: 'Gestão Energética',
    description: 'Soluções em eficiência no fornecimento de energia elétrica para a indústria.',
    subdescription: {
      one: 'Eficiência energética na indústria se dá quando a geração e uso de energia elétrica acontece com menos recursos, mas mantendo, ou aumentando a capacidade de produção.',
      two: 'Uma grande aliada dos empresários que buscam soluções para aumentar a competitividade dos negócios num mercado cada vez mais acirrado e avançado tecnologicamente.'
    },
    pros: [
      {
        title: 'Auditoria',
        description: 'Com os resultados das análises, sua empresa vai poder trabalhar com diferentes escalas e rotinas, além de aproveitar melhor os recursos naturais e conscientizar os colaboradores.'
      },
      {
        title: 'Dimensionamento',
        description: "Gestão integral de energia;Diagnóstico de energia;Monitoramento de energia;Simulação numérica dos Processos;Engenharia e medições;Performance energético."
      },
      {
        title: 'Sustentabilidade',
        description: 'Realizamos projetos e soluções em energia. Podemos ajudar você a atingir suas metas de sustentabilidade de forma rentável. Oferecemos soluções completas em eficiência energética para a indústria.'
      },
      {
        title: 'Focos de atuação',
        description: 'Chillers;Iluminação e Refrigeração;Motores Elétricos;Bombeamento;Caldeiras e Fornos Industriais;Compressores.'
      }
    ]
  },
  {
    reverse: true,
    image: GreenLampImg,
    title: 'Fornecimento de Energia',
    description: 'Cooperativa de fornecimento de energia limpa e renovável sem a necessidade de investimento.',
    subdescription: {
      one: 'Economia de 10% até 25% na conta de luz, com energia 100% limpa para o seu negócio e relatórios inteligentes.',
      two: 'Neste modelo de negócio sua empresa não precisa fazer investimentos ou alterações físicas. '
    },
    pros: [
      {
        title: 'Prático e fácil',
        description: 'Basta assinar e começar a usufruir dos descontos na conta de energia.'
      },
      {
        title: 'Todos tipos de negócios',
        description: "Indicado para qualquer modalidade de negócios ou consumo."
      },
      {
        title: 'Todos os Tamanhos',
        description: 'Indústrias de todos os portes, pequenos e médios negócios que necessitam enxugar custos.'
      },
      {
        title: 'Gestão eficiente',
        description: 'Contratos com valores fixos permitem uma gestão mais eficiente.'
      }
    ]
  },
]
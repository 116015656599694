import styled from "styled-components"

import background from '../../assets/images/contact-bg.webp'
import { patterns } from "../../styled/patterns"

export const El = styled.section`
  background:url(${background});
  background-position:bottom;
  background-size:cover;
  background-repeat:no-repeat;
  padding:192px 0 96px;
  background-color:${p => p.theme.pallete.blue.dark};

  @media (max-width:${patterns._breakpoints.b2}px) {
    padding:19.2rem 0 9.6rem;
  }
`

export const Container = styled.div`
  display:flex;
  gap:15.2rem;
  justify-content:space-between;

  @media (max-width:1140px) {
    flex-direction:column;
  }

  @media (max-width:${patterns._breakpoints.b2}px) {
    gap:8rem;
  }
`

export const ContactInfo = styled.div`
  display:flex;
  flex-direction:column;
  gap:4rem;
  flex:1;
  color:${p => p.theme.pallete.primary};
`

export const ContactTitle = styled.h2`
  font-size:4.8rem;
  font-weight:700;
`

export const ContactText = styled.p`
  font-size:2.4rem;
  font-weight:400;
`

export const EcoArea = styled.div`
  display:flex;
  gap:2rem;
  
  svg {
    height:100%;
    width:auto;
  }

  @media (max-width:440px) {
  
    svg {
      width:100%;
      max-width:30px;
      height:auto;
    }
  }
`

export const EcoText = styled.div`
  span:nth-child(1) {
    font-size:2.4rem;
    font-weight:400;
  }

  span:nth-child(2) {
    font-size:4.8rem;
    font-weight:700;
  }

  @media (max-width:440px) {

    span:nth-child(1) {
      font-size:1.8rem;
    }
  
    span:nth-child(2) {
      font-size:3.6rem;
    }
  }
`

export const FormArea = styled.form`
  flex:1;
  display:flex;
  flex-direction:column;
  gap:2rem;
  max-width:42.4rem;

  @media (max-width:1140px) {
    max-width:600px;
    min-width:42.4rem;
  }

  @media (max-width:440px) {
    max-width:240px;
  }
`

export const MessageArea = styled.textarea<{ isError: boolean; }>`
  resize:none;
  border:none;
  outline:none;
  border-radius:8px;
  font-size:1.8rem;
  font-weight:600;
  background:${p => p.isError ? `
    linear-gradient(0deg, rgba(210, 110, 81, 0.3), rgba(210, 110, 81, 0.3)), #FFFFFF;
  ` : p.theme.pallete.primary};
  color:${p => p.isError ? p.theme.pallete.gold : p.theme.pallete.blue.dark};
  padding:2.1rem 3.2rem;
  transition:border-color .3s, color .3s, background-color .3s;

  &::placeholder {
    color:${p => p.isError ? p.theme.pallete.gold : p.theme.pallete.grey};
    transition:color .3s;
  }
`

export const FormBottom = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
`

export const CheckboxArea = styled.div`
  display:flex;
  align-items:center;
  gap:10px;
  cursor:pointer;

  span {
    font-size:1.4rem;
    font-weight:400;
    color:${p => p.theme.pallete.primary};
  }
`

export const CheckSquare = styled.div`
  border-radius:4px;
  display:grid;
  place-items:center;
  width:3.2rem;
  height:3.2rem;
  background-color:${p => p.theme.pallete.primary};

  svg {
    width:2.6rem;
    height:auto;
  }
`

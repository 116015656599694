import { useRef, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './styled/theme';
import Hero from './sections/Hero';
import ServicesResume from './sections/ServicesResume';
import Service from './sections/Service';
import Clients from './sections/Clients';
import Contact from './sections/Contact';
import Footer from './sections/Footer';
import Global from './styled/global';

import { servicesDetails } from './utils/consts/servicesDetails';
import FeedbackModal from './components/FeedBackModal';
import { toggleModal } from './utils/functions/toggleModal';

function App() {

  const [modalInfo, setModalInfo] = useState({
    showing: false,
    title: '',
    message: ''
  })

  const servicesRef = useRef<HTMLElement | null>(null)
  const contactRef = useRef<HTMLElement | null>(null)

  const serviceOneReg = useRef<HTMLElement | null>(null)
  const serviceTwoReg = useRef<HTMLElement | null>(null)
  const serviceThreeReg = useRef<HTMLElement | null>(null)
  const serviceFourReg = useRef<HTMLElement | null>(null)

  const servicesRefsRelations = [
    serviceOneReg,
    serviceTwoReg,
    serviceThreeReg,
    serviceFourReg,
  ]

  const handleToggleModal = (
    show?: boolean,
    title?: string,
    message?: string,
  ) => {
    toggleModal({
      modalShowing: modalInfo.showing,
      setModalInfo,
      show,
      title,
      message
    })
  }


  return (
    <ThemeProvider theme={theme}>
      <FeedbackModal
        showing={modalInfo.showing}
        title={modalInfo.title}
        feedback={modalInfo.message}
        toggleModal={handleToggleModal}
      />
      <Global />
      <Hero servicesRf={servicesRef} contactRf={contactRef} />
      <ServicesResume ref={servicesRef} servicesRelations={servicesRefsRelations} />
      {servicesDetails.map((s, k) => (
        <Service
          key={k}
          details={s}
          ref={servicesRefsRelations[k]}
          last={k === servicesDetails.length - 1}
        />
      ))}
      <Clients />
      <Contact ref={contactRef} toggleModal={handleToggleModal} />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
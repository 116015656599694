import * as S from './styles'

import { ReactComponent as FlashIcon } from "../../assets/icons/flash.svg"
import { ReactComponent as SunIcon } from "../../assets/icons/sun.svg"
import { ReactComponent as IndustryIcon } from "../../assets/icons/industry.svg"
import { ReactComponent as LampIcon } from "../../assets/icons/lamp.svg"


type Props = {
  icon: 'flash' | 'sun' | 'industry' | 'lamp';
  title: string;
  description: string;
  onClick: ()=>void;
}

const ServiceCard = ({ icon, title, description, onClick }: Props) => {

  let i = <></>

  switch (icon) {
    case 'flash':
      i = <FlashIcon />
      break;
    case 'sun':
      i = <SunIcon />
      break;
    case 'industry':
      i = <IndustryIcon />
      break;
    case 'lamp':
      i = <LampIcon />
      break;
    default:
      i = <FlashIcon />
      break;
  }

  return (
    <S.Card onClick={onClick}>
      {i}
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
    </S.Card>
  )

}


export default ServiceCard
import * as S from './styles'


type Info = {
  companyName: string;
  logo: string;
}

export const renderClientImg = (info: Info, k: number) => {

  const ws = window.document.body.clientWidth

  const sizes = {
    big: ws > 1140,
    small: ws < 1140
  }

  return (
    <S.ClientCard key={k} className={k === 24 ? 'optional' : ''}>
      <img
        src={info.logo}
        alt={info.companyName}
        width={sizes.big ? 128 : 96}
        height={sizes.big ? 85.5 : 64.18}
        loading='lazy'
      />
    </S.ClientCard>
  )
}
import { ErrorsChecks } from "../types/errorschecks"
import masks from "./maks"


type ComumProps = {
  t: string;
  errors: ErrorsChecks;
  setErrors: (value: React.SetStateAction<ErrorsChecks>) => void;
  setState: (value: React.SetStateAction<string>) => void;
}

export const handleName = ({ t, errors, setErrors, setState }: ComumProps) => {
  setState(t)

  setErrors({
    ...errors,
    name: { ...errors.name, is: false }
  })
}

export const handleEmail = ({ t, errors, setErrors, setState }: ComumProps) => {
  const masked = masks.email(t)
  setState(masked.parsed)

  setErrors({
    ...errors,
    email: { ...errors.email, is: false, match:masked.matched }
  })
}

export const handleTelephone = ({ t, errors, setErrors, setState }: ComumProps) => {
  const masked = masks.phone(t)
  setState(masked.parsed)

  setErrors({
    ...errors,
    telephone: { ...errors.telephone, is: false, match:masked.matched }
  })
}

export const handleMessage = ({ t, errors, setErrors, setState }: ComumProps) => {
  setErrors({
    ...errors,
    message: { ...errors.message, is: false }
  })

  setState(t)
}